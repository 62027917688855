import { TextInput } from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const Input = ({
	autoComplete,
	autoFocus,
	counter = false,
	defaultValue,
	description,
	label = true,
	max,
	min,
	name,
	pattern,
	placeholder,
	required,
	type,
	value,
	...props
}) => {
	const {
		formState: { errors },
		register,
		setFocus,
	} = useFormContext();
	const currentValue = useWatch({ name });

	const error = errors && errors[name] ? errors[name] : null;

	useEffect(() => {
		if (autoFocus) setFocus(name);
	}, [autoFocus, name, setFocus]);

	if (!name) return <></>;
	if (type == 'hidden') {
		return (
			<input
				defaultValue={defaultValue}
				placeholder={placeholder}
				type={type}
				value={value}
				{...props}
				{...register(name)}
			/>
		);
	}

	return (
		<TextInput
			autoComplete={autoComplete}
			autoFocus={autoFocus}
			defaultValue={defaultValue}
			description={
				description || counter ? (
					<>
						{description}
						{counter && type == 'text' && (
							<>
								{description ? ' - ' : null}
								{currentValue?.length ?? 0} {max ? ` / ${max}` : ''}
							</>
						)}
					</>
				) : undefined
			}
			error={error?.message}
			label={label ? placeholder : null}
			maxLength={max}
			min={min}
			pattern={pattern}
			placeholder={placeholder}
			required={required}
			type={type}
			value={value}
			{...props}
			{...register(name)}
		/>
	);
};

Input.propTypes = {
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	counter: PropTypes.bool,
	defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	description: PropTypes.string,
	label: PropTypes.bool,
	max: PropTypes.number,
	min: PropTypes.number,
	name: PropTypes.string,
	pattern: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	type: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default Input;
