import { Modal, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AdminContext } from '../../context/admin';
import useContentManagerForm from '../../forms/content_manager';
import useCache from '../../hooks/use_cache';
import useForm from '../../hooks/use_form';
import useRequest from '../../hooks/use_request';
import Form from '../form';
import Editor from '../form/editor';
import SelectRequest from '../form/select_request';
import Submit from '../form/submit';
import Suspense from '../framework/suspense';

const IssueHandle = ({ button, issue_id }) => {
	const [opened, { close, open }] = useDisclosure(false);
	const TriggerModalButton = button;

	return (
		<>
			<TriggerModalButton open={open} />

			<Modal
				centered
				onClose={close}
				opened={opened}
				size="xl"
				title={issue_id ? 'Update issue' : 'Create issue'}
			>
				<Suspense>
					<Request close={close} issue_id={issue_id} opened={opened} />
				</Suspense>
			</Modal>
		</>
	);
};

const Request = ({ close, issue_id, opened }) => {
	const { revalidate } = useCache();
	const { setSetting, settings } = useContext(AdminContext);
	const { handleSubmit, resolver } = useContentManagerForm({
		action: issue_id ? 'edit' : 'create',
	});
	const { methods: formMethods } = useForm();
	const { data: contentData, mutate } = useRequest(
		opened && issue_id ? 'content_manager.getData' : null,
		{
			dedupingInterval: 0,
			params: {
				id: issue_id,
				model: 'Issue',
			},
			revalidateOnMount: true,
		},
	);

	const onSubmit = async (values) => {
		const result = await handleSubmit({
			id: issue_id,
			model: 'Issue',
			...values,
		});
		if (result?.status == 'ok') {
			setSetting('partner_id', values.partner_id);
			formMethods.reset();
			await mutate();
			await revalidate('content_manager.list', ['Issue']);
			close();
		}
	};

	return (
		<Form methods={formMethods} onSubmit={onSubmit} resolver={resolver}>
			<Stack>
				<SelectRequest
					defaultValue={`${contentData?.data?.partner_id ?? settings?.partner_id}`}
					model="Partner"
					name="partner_id"
					placeholder="Partner"
				/>

				<SelectRequest
					defaultValue={contentData?.data?.user_id + ''}
					model="User"
					name="user_id"
					placeholder="User"
				/>

				<Editor
					defaultType="editor"
					defaultValue={contentData?.data?.content ?? ''}
					format="markdown"
					name="content"
					rows={10}
				/>

				<Submit fullWidth name={issue_id ? 'Update issue' : 'Create issue'} />
			</Stack>
		</Form>
	);
};

IssueHandle.propTypes = {
	button: PropTypes.func,
	issue_id: PropTypes.number,
};

Request.propTypes = {
	close: PropTypes.func,
	issue_id: PropTypes.number,
	opened: PropTypes.bool,
};

export default IssueHandle;
