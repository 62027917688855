import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import useToast from '../components/framework/toast';
import { RequestError } from '../error/request';
import useMutate from '../hooks/use_mutate';

const validationSchema = Yup.object({});

function useContentManagerForm({ action } = {}) {
	const { isMutating, trigger } = useMutate(
		`content_manager.${action == 'edit' ? 'updateData' : 'createData'}`,
	);
	const { errorToast, successToast } = useToast();
	const initialValues = {};

	const handleSubmit = async (data) => {
		try {
			for (const key in data) {
				if (data[key] instanceof File) {
					data._file = {
						data: await readFile(data[key]),
						key,
						name: data[key].name,
						type: data[key].type,
					};
					delete data[key];
				} else if (data[key] instanceof FileList) {
					if (data[key].length == 0) {
						delete data[key];
					}
				}
			}

			const result = await trigger(data);
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			if (data.id) {
				successToast({
					description: `Updated content data in model "${data.model}".`,
					title: 'Updated',
				});
			} else {
				successToast({
					description: `Created new content data in model "${data.model}".`,
					title: 'Created',
				});
			}

			return result;
		} catch (error) {
			let description = 'Please check form data.';
			const title = action == 'edit' ? 'Update problem' : 'Create problem';

			errorToast({
				description,
				error,
				title,
			});
		}
	};

	function readFile(file) {
		return new Promise((resolve, reject) => {
			let reader = new FileReader();
			reader.onload = () => {
				resolve(reader.result);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	}

	const resolver = yupResolver(validationSchema);

	return {
		handleSubmit,
		initialValues,
		isMutating,
		resolver,
	};
}

export default useContentManagerForm;
