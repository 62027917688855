import {
	Button,
	Card,
	CopyButton,
	Group,
	Menu,
	Stack,
	Text,
	TypographyStylesProvider,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	IconCopy,
	IconEye,
	IconPrompt,
	IconRefresh,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import ReactMarkdown from 'react-markdown';

import { AdminContext } from '../../context/admin';
import { RequestError } from '../../error/request';
import useMutate from '../../hooks/use_mutate';
import { formatDateTime } from '../../services/utils/helpers';
import AiCheckbox from '../ai/checkbox';
import AiPrompts from '../ai/prompts';
import ActionCard from '../framework/action_card';
import Link from '../framework/link';
import useToast from '../framework/toast';

const Item = ({ collapsed = true, handleRefresh, item }) => {
	const {
		constants: { languages },
	} = useContext(AdminContext);
	const [openedAiPrompt, { close: closeAiPrompt, toggle: toggleAiPrompt }] =
		useDisclosure(false);
	const { isMutating, trigger: triggerRegenerate } =
		useMutate('dataset.regenerate');
	const { errorToast } = useToast();

	const handleRegenerate = async (dataset_item_id, language) => {
		try {
			const result = await triggerRegenerate({
				dataset_item_id,
				language,
			});
			if (result?.status == 'error') {
				throw new RequestError({ request: result });
			}

			if (result && handleRefresh) {
				handleRefresh();
			}
		} catch (error) {
			errorToast({
				description: 'Unable to regenerate dataset item, please try again.',
				error,
				title: 'Error',
			});
		}
	};

	return (
		<ActionCard
			isOpened={collapsed}
			leftSection={
				<Group justify="space-between">
					<Stack gap={0}>
						<Text size="xs">{item.dataset.name}</Text>
						<Text size="xs">{formatDateTime(item.created_at)}</Text>
					</Stack>
				</Group>
			}
		>
			<Card.Section inheritPadding mb="sm" py="sm" withBorder>
				<TypographyStylesProvider>
					<ReactMarkdown>{item.content}</ReactMarkdown>
				</TypographyStylesProvider>
			</Card.Section>

			{openedAiPrompt && item?.prompts?.length && (
				<Card.Section inheritPadding mb="sm" pb="sm" withBorder>
					<AiPrompts
						items={item?.prompts}
						onClose={closeAiPrompt}
						template_id={item.template_id}
					/>
				</Card.Section>
			)}

			<Group justify="space-between">
				<Group>
					<CopyButton value={item.content}>
						{({ copied, copy }) => (
							<Button
								color={copied ? 'green' : ''}
								leftSection={<IconCopy />}
								onClick={copy}
								size="xs"
								variant="light"
							>
								{copied ? 'Copied' : 'Copy'}
							</Button>
						)}
					</CopyButton>

					{item?.prompts?.length && (
						<Button
							leftSection={<IconPrompt />}
							onClick={toggleAiPrompt}
							size="xs"
							variant="light"
						>
							Prompt
						</Button>
					)}

					{item?.id && (
						<>
							<Button
								component={Link}
								leftSection={<IconEye />}
								size="xs"
								to={`/content_manager/DatasetItem/${item.id}/`}
								variant="light"
							>
								View
							</Button>

							<Menu shadow="md">
								<Menu.Target>
									<Button
										leftSection={<IconRefresh />}
										loading={isMutating}
										size="xs"
										variant="light"
									>
										Regenerate
									</Button>
								</Menu.Target>

								<Menu.Dropdown>
									<Menu.Label>Language</Menu.Label>

									{languages?.map((language, index) => {
										return (
											<Menu.Item
												key={index}
												onClick={() => handleRegenerate(item.id, language.id)}
											>
												{language.name}
											</Menu.Item>
										);
									})}
								</Menu.Dropdown>
							</Menu>
						</>
					)}
				</Group>

				<AiCheckbox
					data={{
						dataset_item: { id: item.id },
					}}
					type="dataset_item"
				/>
			</Group>
		</ActionCard>
	);
};

Item.propTypes = {
	collapsed: PropTypes.bool,
	handleRefresh: PropTypes.func,
	item: PropTypes.object,
};

export default Item;
