import { Checkbox, Tooltip } from '@mantine/core';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo } from 'react';

import { AiContext } from '../../context/ai';

const AiCheckbox = ({
	color,
	data,
	description,
	disabled,
	label,
	tooltip_label,
	type,
}) => {
	const { options, toggleOption } = useContext(AiContext);

	const getKeyValue = useCallback(
		(item) => {
			let out = null;

			if (type == 'analysis') {
				out = {
					key: `${type}_${item?.analysis?.id}`,
					value: { analysis_id: item?.analysis?.id, type },
				};
			} else if (type == 'analysis_value') {
				out = {
					key: `${type}_${item?.analysis_value?.id}`,
					value: { analysis_value_id: item?.analysis_value?.id, type },
				};
			} else if (type == 'case_item') {
				out = {
					key: `${type}_${item?.case_item?.id}`,
					value: { case_item_id: item?.case_item?.id, type },
				};
			} else if (type == 'dataset_item') {
				out = {
					key: `${type}_${item?.dataset_item?.id}`,
					value: { dataset_item_id: item?.dataset_item?.id, type },
				};
			} else if (type == 'survey_answer') {
				out = {
					key: `${type}_${item?.survey_answer?.id}`,
					value: { survey_answer_id: item?.survey_answer?.id, type },
				};
			} else if (type == 'user') {
				out = {
					key: `${type}_${item?.user?.id}`,
					value: { type, user_id: item?.user?.id },
				};
			}

			return out;
		},
		[type],
	);

	const handleCheckbox = () => {
		const items = [].concat(data);

		for (const item of items) {
			const result = getKeyValue(item);
			if (!result?.key || !result?.value) return;
			toggleOption(result?.key, result?.value);
		}
	};

	const isChecked = useMemo(() => {
		const items = [].concat(data);

		for (const item of items) {
			const result = getKeyValue(item);
			if (!result?.key) return false;
			return Boolean(options[result?.key]);
		}
	}, [data, getKeyValue, options]);

	return (
		<Tooltip label={tooltip_label ?? `AI (${type})`}>
			<Checkbox
				checked={isChecked}
				color={color}
				description={description}
				disabled={disabled}
				label={label}
				onChange={handleCheckbox}
			/>
		</Tooltip>
	);
};

AiCheckbox.propTypes = {
	color: PropTypes.string,
	data: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.object,
		PropTypes.string,
	]),
	description: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	tooltip_label: PropTypes.string,
	type: PropTypes.string,
};

export default AiCheckbox;
