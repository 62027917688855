import {
	differenceInDays,
	intervalToDuration,
	intlFormatDistance,
} from 'date-fns';

export function calculatePercentage(current, previous) {
	let difference = current - previous;
	let out = { type: null, value: 0 };

	if (difference === 0) {
		return out;
	} else if (difference < 0) {
		// Decrease
		difference = previous - current;
		out.value = (difference / previous) * 100;
		out.type = 'decrease';
	} else {
		// Increase
		difference = current - previous;
		out.value = (difference / previous) * 100;
		out.type = 'increase';
	}

	return out;
}

export function debounce(callback, delay = 250) {
	let timeout = null;

	return (...args) => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			callback(...args);
		}, delay);
	};
}

export function getDifferenceInDays(date) {
	return Number(differenceInDays(new Date(), new Date(date)));
}

export function formatAmount(data, options = {}, locale = 'sv-SE') {
	const defaultOptions = {
		currency: 'SEK',
		minimumFractionDigits: 0,
		style: 'currency',
		trailingZeroDisplay: 'stripIfInteger',
	};
	options = { ...defaultOptions, ...options };

	return new Intl.NumberFormat(locale, options).format(data);
}

export function formatDateTime(date, options = {}, locale = 'sv-SE') {
	const defaultOptions = {
		dateStyle: 'short',
		timeStyle: 'short',
	};
	options = { ...defaultOptions, ...options };

	return new Intl.DateTimeFormat(locale, options).format(new Date(date));
}

export function formatDateTimeDistance(date, options = {}) {
	const defaultOptions = {
		locale: 'sv-SE',
	};
	options = { ...defaultOptions, ...options };

	return intlFormatDistance(new Date(date), new Date(), options);
}

export function formatDateTimePassed(date) {
	const duration = intervalToDuration({
		end: new Date(),
		start: new Date(date),
	});
	const parts = [];

	if (duration.years) {
		parts.push(`${duration.years} ${duration.years === 1 ? 'year' : 'years'}`);
	}
	if (duration.months) {
		parts.push(
			`${duration.months} ${duration.months === 1 ? 'month' : 'months'}`,
		);
	}
	if (duration.days) {
		parts.push(`${duration.days} ${duration.days === 1 ? 'day' : 'days'}`);
	}
	if (duration.hours) {
		parts.push(`${duration.hours} ${duration.hours === 1 ? 'hour' : 'hours'}`);
	}
	if (duration.minutes) {
		parts.push(
			`${duration.minutes} ${duration.minutes === 1 ? 'minute' : 'minutes'}`,
		);
	}
	/*
	if (duration.seconds) {
		parts.push(
			`${duration.seconds} ${duration.seconds === 1 ? 'second' : 'seconds'}`,
		);
	}*/

	return parts.length > 0 ? parts.join(', ') : '';
}

export function formatXml(data) {
	let formatted = '';
	let indent = '';
	const tab = '  ';

	data.split(/>\s*</).forEach(function (node) {
		if (node.match(/^\/\w/)) indent = indent.substring(tab.length);
		formatted += indent + '<' + node + '>\r\n';
		if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;
	});

	return formatted.substring(1, formatted.length - 3);
}

export function getAge(date) {
	if (!date) return null;

	const birth_date = new Date(date);
	const today = new Date();
	let age = today.getFullYear() - birth_date.getFullYear();
	const m = today.getMonth() - birth_date.getMonth();
	if (m < 0 || (m === 0 && today.getDate() < birth_date.getDate())) {
		age--;
	}
	return age;
}

export function isJson(item) {
	item = typeof item !== 'string' ? JSON.stringify(item) : item;

	try {
		item = JSON.parse(item);
	} catch (e) {
		return false;
	}

	if (typeof item === 'object' && item !== null) {
		return true;
	}

	return false;
}

export function isString(item) {
	return typeof item === 'string' || item instanceof String;
}

export function isXml(item) {
	if (typeof item !== 'string') return false;
	return item.indexOf('<?xml') !== -1;
}

export function rearrangeObject(data, priority_keys) {
	return {
		...Object.fromEntries(
			priority_keys.filter((key) => key in data).map((key) => [key, data[key]]),
		),
		...Object.fromEntries(
			Object.keys(data)
				.filter((key) => !priority_keys.includes(key))
				.map((key) => [key, data[key]]),
		),
	};
}

export function sortObjectByKey(data, reverse = false) {
	let keys = Object.keys(data).sort();
	const out = {};

	if (reverse) keys.reverse();

	for (const key of keys) {
		out[key] = data[key];
	}

	return out;
}

export function templateFormat({ data = {}, template = '' } = {}) {
	let keys = Object.keys(data);
	let out = '';
	let template_function = 'return `' + template + '`';
	let values = keys.map((key) => {
		return data[key];
	});

	out = new (Function.prototype.bind.apply(
		Function,
		[null].concat(keys, [template_function]),
	))().apply(undefined, values);

	return out;
}

export function titleCase(data) {
	if (!data) return data;

	return data
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ')
		.split('-')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join('-');
}

export function toCsv(array) {
	const keys = Object.keys(array[0]);
	let out = '"' + keys.join('","') + '"\n';

	array.forEach(function (obj) {
		out += '"' + keys.map((k) => obj[k]).join('","') + '"\n';
	});

	return out;
}
