import { ActionIcon, Card, Collapse, Group, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	IconChevronDown,
	IconChevronUp,
	IconGripVertical,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

const ActionCard = ({
	children,
	hasHeader = true,
	id,
	isMoveable = false,
	isOpened = true,
	isTogglable = true,
	leftSection,
	rightSection,
	...props
}) => {
	const [opened, { toggle }] = useDisclosure(
		hasHeader && isTogglable ? isOpened : true,
	);

	return (
		<Card
			mb="md"
			padding={opened ? 'md' : ''}
			radius="md"
			shadow="sm"
			withBorder
			{...props}
		>
			{hasHeader && (
				<Card.Section mb={opened ? 'sm' : ''} px="xs" py="xs" withBorder>
					<Group justify="space-between" wrap="nowrap">
						<Group gap="xs" wrap="nowrap">
							{isMoveable && (
								<ActionIcon color="gray" size="xs" variant="transparent">
									<IconGripVertical size={20} />
								</ActionIcon>
							)}
							{leftSection && <>{leftSection}</>}
						</Group>
						<Group justify="flex-end" wrap="nowrap">
							{rightSection && <>{rightSection}</>}
							{isTogglable && (
								<ActionIcon
									color="gray"
									onClick={toggle}
									size="xs"
									variant="transparent"
								>
									{opened ? (
										<Tooltip label="Collapse">
											<IconChevronUp size={20} />
										</Tooltip>
									) : (
										<Tooltip label="Expand">
											<IconChevronDown size={20} />
										</Tooltip>
									)}
								</ActionIcon>
							)}
						</Group>
					</Group>
				</Card.Section>
			)}

			<Collapse in={opened}>{children}</Collapse>
		</Card>
	);
};

ActionCard.propTypes = {
	children: PropTypes.node,
	hasHeader: PropTypes.bool,
	id: PropTypes.string,
	isMoveable: PropTypes.bool,
	isOpened: PropTypes.bool,
	isTogglable: PropTypes.bool,
	leftSection: PropTypes.node,
	rightSection: PropTypes.node,
};

export default ActionCard;
