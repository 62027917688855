import {
	ActionIcon,
	Alert,
	Box,
	CopyButton,
	Divider,
	Group,
	Stack,
	Text,
	Title,
	Tooltip,
} from '@mantine/core';
import { IconPencil } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React from 'react';

import Link from '../framework/link';

const AiPrompts = ({ items, onClose, settings, template_id, variant }) => {
	if (!items?.length) return;

	return (
		<Alert
			onClose={onClose}
			title={
				<Group>
					Prompts
					{template_id && (
						<ActionIcon
							color="gray"
							component={Link}
							size="xs"
							to={`/content_manager/Template/${template_id}/`}
							variant="transparent"
						>
							<IconPencil />
						</ActionIcon>
					)}
				</Group>
			}
			variant={variant}
			withCloseButton={Boolean(onClose)}
		>
			{settings && Object.keys(settings)?.length > 0 && (
				<Box>
					<Divider
						fw={700}
						label="Settings"
						labelPosition="left"
						my="xs"
						tt="uppercase"
					/>

					<Stack gap="0">
						{Object.entries(settings).map(([key, value], index) => {
							return (
								<Text fz="sm" key={index}>
									{key}:{' '}
									<Text fw={700} fz="sm" span>
										{value}
									</Text>
								</Text>
							);
						})}
					</Stack>
				</Box>
			)}
			{items?.map((invocation, index1) => {
				return (
					<>
						{items.length > 2 && (
							<Title mb="xs" size="sm">
								Invocation {index1 + 1}
							</Title>
						)}

						<Stack>
							{invocation.map((value, index) => {
								return (
									<PromptItem
										content={value.content}
										key={index}
										role={value.role}
									/>
								);
							})}
						</Stack>
					</>
				);
			})}
		</Alert>
	);
};

const PromptItem = ({ content, role }) => {
	const label = (
		<CopyButton value={content}>
			{({ copied, copy }) => (
				<Tooltip label={copied ? 'Copied' : 'Copy'} position="right" withArrow>
					<Box onClick={copy}>{role}</Box>
				</Tooltip>
			)}
		</CopyButton>
	);

	return (
		<Box style={{ whiteSpace: 'pre-line' }}>
			<Divider
				fw={700}
				label={label}
				labelPosition="left"
				my="xs"
				tt="uppercase"
			/>

			{content}
		</Box>
	);
};

AiPrompts.propTypes = {
	items: PropTypes.array,
	onClose: PropTypes.func,
	settings: PropTypes.object,
	template_id: PropTypes.number,
	variant: PropTypes.string,
};

PromptItem.propTypes = {
	content: PropTypes.string,
	role: PropTypes.string,
};

export default AiPrompts;
