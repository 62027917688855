import {
	ActionIcon,
	Affix,
	Anchor,
	AppShell,
	Avatar,
	Burger,
	Group,
	Image,
	Indicator,
	Menu,
	Text,
	Tooltip,
	useMantineColorScheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { navigate, useLocation } from '@reach/router';
import {
	IconAi,
	IconChevronDown,
	IconLogout,
	IconMoon,
	IconSearch,
	IconSun,
	IconUser,
	IconWindowMaximize,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import { AdminContext } from '../../context/admin';
import { AiContext } from '../../context/ai';
import useMutate from '../../hooks/use_mutate';
import logoBlack from '../../images/framework/logo-black.svg';
import logoWhite from '../../images/framework/logo-white.svg';
import authentication from '../../libs/authentication';
import Sidebar from '../sidebar';
import Aside from './aside';
import Link from './link';

const Framework = ({ children }) => {
	const { hasOptions: hasAiOptions } = useContext(AiContext);
	const {
		aSideOpen,
		aSideState,
		constants: { aside_sizes },
		settings,
		user,
	} = useContext(AdminContext);
	const [navBarOpened, { close, toggle }] = useDisclosure();
	const [fullscreen, { toggle: toggleFullscreen }] = useDisclosure(false);
	const location = useLocation();
	const { colorScheme, toggleColorScheme } = useMantineColorScheme();
	const { trigger } = useMutate('authentication.signout');
	const framework = location?.state?.print ? false : true;

	const handleSignout = async () => {
		await trigger();
		authentication.signOut();
		navigate('/', { replace: true });
	};

	if (!framework) return <>{children}</>;

	return (
		<AppShell
			aside={{
				width: aSideState?.component
					? aside_sizes[settings?.aside_size] ?? 450
					: 0,
			}}
			disabled={fullscreen}
			header={{ height: 46 }}
			navbar={{
				breakpoint: 'sm',
				collapsed: { mobile: !navBarOpened },
				width: settings?.menu_size == 'small' ? 80 : 220,
			}}
			padding="md"
		>
			{aSideState?.component && (
				<AppShell.Aside p={5}>
					<Aside />
				</AppShell.Aside>
			)}
			<AppShell.Header>
				<Group
					h="46"
					justify="space-between"
					preventGrowOverflow={false}
					px="md"
					wrap="nowrap"
				>
					<Burger
						hiddenFrom="sm"
						onClick={toggle}
						opened={navBarOpened}
						size="sm"
					/>
					<Anchor component={Link} fw={700} size="xl" to="/">
						<Image
							fit="contain"
							h="32"
							src={colorScheme === 'light' ? logoBlack : logoWhite}
						/>
					</Anchor>
					<Group>
						<ActionIcon
							color="black"
							component={Link}
							hiddenFrom="sm"
							to="/search/"
							variant="white"
						>
							<Tooltip label="Search, mod+J">
								<IconSearch />
							</Tooltip>
						</ActionIcon>
						<Menu shadow="md" width={200}>
							<Menu.Target>
								<Group>
									<Text display={{ base: 'none', md: 'flex' }}>
										{user?.first_name} {user?.last_name}
									</Text>
									<Avatar size={'sm'} src={user?.image || null} />
									<IconChevronDown />
								</Group>
							</Menu.Target>

							<Menu.Dropdown>
								<Menu.Item
									component={Link}
									leftSection={<IconUser />}
									to={`/profile/`}
								>
									Profile
								</Menu.Item>
								<Menu.Item
									leftSection={
										colorScheme === 'light' ? <IconMoon /> : <IconSun />
									}
									onClick={toggleColorScheme}
								>
									{colorScheme === 'light'
										? 'Switch to dark mode'
										: 'Switch to light mode'}
								</Menu.Item>
								<Menu.Divider />
								<Menu.Item leftSection={<IconLogout />} onClick={handleSignout}>
									Sign out
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Group>
				</Group>
			</AppShell.Header>

			<AppShell.Navbar p="md">
				<Sidebar close={close} />
			</AppShell.Navbar>

			<AppShell.Main bg={colorScheme === 'dark' ? 'gray.9' : 'gray.0'}>
				{children}

				<Affix position={{ bottom: 60, left: 17 }}>
					<Tooltip label="Fullscreen" position="right">
						<ActionIcon
							color="gray"
							display={{ base: 'none', sm: 'block' }}
							onClick={toggleFullscreen}
							variant="subtle"
						>
							<IconWindowMaximize />
						</ActionIcon>
					</Tooltip>
				</Affix>

				{!aSideState?.component && !fullscreen && (
					<Affix position={{ bottom: 20, right: 20 }}>
						<Indicator disabled={!hasAiOptions} size={6}>
							<ActionIcon
								color="gray"
								onClick={() => aSideOpen({ component: 'ai' })}
								variant="subtle"
							>
								<IconAi />
							</ActionIcon>
						</Indicator>
					</Affix>
				)}
			</AppShell.Main>
		</AppShell>
	);
};

Framework.propTypes = {
	children: PropTypes.node,
};

export default Framework;
