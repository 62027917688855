import { Badge as MantineBadge } from '@mantine/core';
import PropTypes from 'prop-types';
import React from 'react';

const Badge = ({ id, variant = 'light', ...props }) => {
	const colors = [
		'blue',
		'cyan',
		'grape',
		'green',
		'indigo',
		'lime',
		'orange',
		'pink',
		'red',
		'teal',
		'violet',
		'yellow',
	];
	let color = null;
	const match = {
		accepted: 'green',
		activated: 'green',
		active: 'green',
		approved: 'green',
		archived: 'red',
		bookable: 'teal',
		coming: 'orange',
		default: 'green',
		draft: 'yellow',
		error: 'red',
		extreme: 'red',
		false: 'red',
		female: 'pink',
		finished: 'green',
		forecast: 'yellow',
		full: 'green',
		high: 'red',
		incoming: 'green',
		low: 'yellow',
		male: 'blue',
		marker: 'blue',
		masked: 'orange',
		minimal: 'blue',
		moderate: 'yellow',
		negative: 'red',
		normal: 'green',
		open: 'yellow',
		order: 'blue',
		orderable: 'red',
		outgoing: 'red',
		owner: 'green',
		pending: 'red',
		positive: 'green',
		process: 'yellow',
		processed: 'green',
		random: 'yellow',
		read: 'green',
		realized: 'green',
		refunded: 'yellow',
		refunded_fully: 'green',
		refunded_partially: 'yellow',
		resolved: 'green',
		review: 'red',
		row: 'orange',
		sent: 'green',
		serie: 'green',
		severe: 'pink',
		solved: 'green',
		true: 'green',
		unspecified: 'yellow',
		unused: 'blue',
		verify: 'yellow',
	};

	if (match[id]) {
		color = match[id];
	} else if (typeof id === 'number') {
		color = colors[id % colors.length];
	}

	if (!props?.children) return;
	return <MantineBadge color={color} variant={variant} {...props} />;
};

Badge.propTypes = {
	children: PropTypes.node,
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	variant: PropTypes.string,
};

export default Badge;
