import {
	ActionIcon,
	AppShell,
	Divider,
	Menu,
	NavLink,
	ScrollArea,
	Tooltip,
} from '@mantine/core';
import {
	IconActivity,
	IconAt,
	IconBasket,
	IconBiohazard,
	IconCheck,
	IconChevronLeftPipe,
	IconChevronRightPipe,
	IconCurrencyDollar,
	IconFile,
	IconGiftCard,
	IconHanger,
	IconHome,
	IconLine,
	IconMap,
	IconMessage,
	IconPlaylistAdd,
	IconSearch,
	IconTools,
	IconUser,
} from '@tabler/icons-react';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import { AdminContext } from '../../context/admin';
import useContentSearch from '../../forms/content_search';
import useForm from '../../hooks/use_form';
import Form from '../form';
import Input from '../form/input';
import Link from '../framework/link';
import IssueHandle from '../modal/issue_handle';

const LinkItems = [
	{ icon: <IconHome />, name: 'Dashboard', url: '/' },
	{ icon: <IconActivity />, name: 'Analysis', url: 'analysis/' },
	{ icon: <IconAt />, name: 'Case', url: 'case/' },
	{ icon: <IconMessage />, name: 'Message', url: 'message/' },
	{ icon: <IconCurrencyDollar />, name: 'Statistics', url: 'statistics/' },
	{ icon: <IconTools />, name: 'Tools', url: 'tools/' },
	{ icon: <IconFile />, name: 'Content manager', url: 'content_manager/' },
];

const Search = ({ close }) => {
	const { handleSubmit, resolver } = useContentSearch();
	const { settings } = useContext(AdminContext);
	const { methods: formMethods } = useForm();
	const [model, setModel] = useState('User');
	const searchOptions = [
		{
			icon: <IconActivity size={14} />,
			model: 'Analysis',
		},
		{
			icon: <IconMap size={14} />,
			model: 'Location',
		},
		{
			icon: <IconBasket size={14} />,
			model: 'Order',
		},
		{
			icon: <IconLine size={14} />,
			model: 'Mapping',
		},
		{
			icon: <IconBiohazard size={14} />,
			model: 'Marker',
		},
		{
			icon: <IconHanger size={14} />,
			model: 'Product',
		},
		{
			icon: <IconUser size={14} />,
			model: 'User',
		},
		{
			icon: <IconGiftCard size={14} />,
			model: 'Voucher',
		},
	];
	const currentOption = searchOptions.find((item) => item.model == model);

	const onSubmit = (event) => {
		handleSubmit({ search: event.search, url: `/content_manager/${model}/` });
		close();
	};

	const ChangeModel = () => (
		<Menu>
			<Menu.Target>
				<ActionIcon variant="transparent">{currentOption.icon}</ActionIcon>
			</Menu.Target>

			<Menu.Dropdown>
				{searchOptions.map((item, index) => {
					return (
						<Menu.Item
							key={index}
							leftSection={item.icon}
							onClick={() => setModel(item.model)}
							rightSection={
								currentOption.model == item.model ? (
									<IconCheck size={10} />
								) : null
							}
						>
							{item.model}
						</Menu.Item>
					);
				})}
			</Menu.Dropdown>
		</Menu>
	);

	return (
		<>
			{settings?.menu_size == 'small' ? (
				<Tooltip
					disabled={settings?.menu_size != 'small'}
					label="Search"
					position="right"
				>
					<NavLink
						component={Link}
						leftSection={<IconSearch />}
						onClick={close}
						to="/search/"
					/>
				</Tooltip>
			) : (
				<Form methods={formMethods} onSubmit={onSubmit} resolver={resolver}>
					<Input
						label={false}
						leftSection={<ChangeModel />}
						name="search"
						placeholder={`Search for ${model.toLowerCase()}`}
						type="text"
					/>
				</Form>
			)}
		</>
	);
};

const Sidebar = ({ close }) => {
	const { setSetting, settings } = useContext(AdminContext);

	const handleMenuSizeChange = (size) => {
		setSetting('menu_size', size);
	};

	return (
		<>
			<AppShell.Section>
				<Search close={close} />
			</AppShell.Section>

			<AppShell.Section component={ScrollArea} grow mt="md" scrollbars="y">
				{LinkItems.map((link, index) => (
					<Tooltip
						disabled={settings?.menu_size != 'small'}
						key={index}
						label={link.name}
						position="right"
					>
						<NavLink
							component={Link}
							label={settings?.menu_size == 'small' ? '' : link.name}
							leftSection={link.icon}
							onClick={close}
							to={link.url}
						/>
					</Tooltip>
				))}

				<Divider my="md" />

				<IssueHandle
					button={({ open }) => (
						<Tooltip
							disabled={settings?.menu_size != 'small'}
							label="Create Issue"
							position="right"
						>
							<NavLink
								label={settings?.menu_size == 'small' ? '' : 'Create Issue'}
								leftSection={<IconPlaylistAdd />}
								onClick={open}
							/>
						</Tooltip>
					)}
				/>
			</AppShell.Section>

			<AppShell.Section>
				<Tooltip
					label={
						settings?.menu_size == 'small' ? 'Expand menu' : 'Collapse menu'
					}
					position="right"
				>
					<ActionIcon
						color="gray"
						onClick={() =>
							handleMenuSizeChange(
								settings?.menu_size == 'small' ? 'big' : 'small',
							)
						}
						variant="subtle"
						visibleFrom="md"
					>
						{settings?.menu_size == 'small' ? (
							<IconChevronRightPipe />
						) : (
							<IconChevronLeftPipe />
						)}
					</ActionIcon>
				</Tooltip>
			</AppShell.Section>
		</>
	);
};

Search.propTypes = {
	close: PropTypes.func,
};

Sidebar.propTypes = {
	close: PropTypes.func,
};

export default Sidebar;
